<template>
  <div class="video-info-warp">
    <div class="back-box" @click="$router.go(-1)">
      <svg-icon iconClass="leftArrow" class="back"></svg-icon>
    </div>
    <div class="h_video">
      <div
        class="videoAdv"
        v-if="
          advsList.length > 0 &&
          !mediaInfo.isBuy &&
          !(userInfo.vipType && mediaInfo.price == 0 && !mediaInfo.isVip) &&
          !(userInfo.vipType && mediaInfo.isVip) &&
          isShowAdv
        "
      >
        <div class="advBox1">
          <AdvSwiper
            :advList="advsList"
            v-if="advsList.length"
            class="adv"
            :key="advsList[0].pictureUrl"
          />
          <div class="timer_close" v-if="userInfo.vipType || mediaInfo.isBuy">
            <div v-if="timer > 0">
              {{ timer }}秒后
              <span @click="isShowAdv = false">关闭</span>
            </div>
            <div v-else>
              <span @click="isShowAdv = false">关闭</span>
            </div>
          </div>
          <div class="timer_close" v-else>
            <div v-if="timer > 0">
              {{ timer }}秒后关闭<span @click="$router.push('/viplist')"
                >VIP跳过广告</span
              >
            </div>
            <div v-else>
              <span @click="isShowAdv = false">关闭</span>
            </div>
          </div>
        </div>
      </div>
      <DPlayer
        class="dplayer"
        :key="mediaInfo.videoUrl"
        :path="mediaInfo.videoUrl"
        :verifyData="data"
        :videoInfo="mediaInfo"
        ref="dp"
        @fanSucvideo="fanSucvideo"
        v-if="showVideo"
      />
    </div>
    <div class="video-info">
      <!-- <div class="videoAdvBox" v-if="gridAdvList.length">
        <div
          class="advItem"
          v-for="(item, index) in gridAdvList"
          :key="index"
          @click="jumpAdd(item)"
        >
          <div class="advImgBox">
            <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
          </div>
          <div class="advTitle">{{ item.name }}</div>
        </div>
      </div> -->
      <div class="advBox">
        <div
          class="advItem"
          v-for="(item, index) in gridAdvList"
          :key="index"
          @click="jumpAdd(item)"
        >
          <div class="advImgBox">
            <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
          </div>
          <div class="advTitle">{{ item.name }}</div>
        </div>
      </div>
      <div class="vipInfo" @click="clickVipInfo">{{ vipInfo }}</div>
      <div class="video-desc">
        <h2>{{ mediaInfo.content ? mediaInfo.content : "未知" }}</h2>
        <p class="video-info-watch">
          {{ mediaInfo.watchTimes | watchCount }}<span>&nbsp;次观看</span>
        </p>
        <!-- 标签 -->
        <div class="video-tag">
          <span
            v-for="(tag, index) in mediaInfo.flags"
            :key="index"
            @click="jumpTag(tag)"
            >{{ tag }}</span
          >
        </div>
        <!-- 博主信息 -->
        <div class="video-blogger">
          <div class="video-blogger-info">
            <div class="blogger-avatar">
              <div v-if="mediaInfo.author" @click="jumpBlogger">
                <ImgDecypt :src="mediaInfo.author.avatarUrl" round />
              </div>
            </div>
            <h2>{{ mediaInfo.author ? mediaInfo.author.nickName : "未知" }}</h2>
          </div>
          <div
            class="video-blogger-follow"
            @click="follow"
            v-show="!(mediaInfo.author && mediaInfo.author.cared)"
          >
            +关注
          </div>
        </div>
        <!-- 视频操作 -->
        <div class="video-operate">
          <div class="video-icon" @click="commentS">
            <span class="message"></span>
            <span>{{ mediaInfo.commentNum | watchCount }}</span>
          </div>
          <div class="video-icon" @click="like">
            <span :class="['like', { actived: mediaInfo.bLike }]"></span>
            <span>{{ mediaInfo.likeNum | watchCount }}</span>
          </div>
          <div class="video-icon" @click="forward">
            <span class="forward"></span>
            <span>转发</span>
          </div>
          <div class="video-icon" @click="shareS">
            <span class="earnVip"></span>
            <span>赚会员</span>
          </div>
          <div class="video-icon" @click="openSelectLine">
            <!-- <span class="earnVip"></span> -->
            <van-icon name="sort" size="18" />
            <span>切换线路</span>
          </div>
        </div>
      </div>
      <!-- 购物车 -->
      <div
        class="shopCart-box"
        v-if="mediaInfo && !mediaInfo.isVip && mediaInfo.price > 0"
      >
        <div class="shop-info">
          <div class="vip-box">
            <span class="novip">非VIP</span><span>{{ mediaInfo.price }}</span>
          </div>
          <div class="vip-box">
            <span>VIP</span><span class="vip">{{ mediaInfo.vipPrice }}</span>
          </div>
        </div>
        <div class="shopCart" @click="buying">
          <img :src="shopCartImg" alt="" />
          <h2>{{ mediaInfo.isBuy ? "已购买" : "购买" }}</h2>
        </div>
        <div class="shop-info">
          <div class="wallet">
            <img :src="qianbao" alt="" />
            余额
          </div>
          <div class="wallet-money">
            <span>=&nbsp;</span>
            <span>{{ this.walletInfo.balance }}</span>
          </div>
        </div>
      </div>
      <!-- 线 -->
      <div class="line"></div>
      <!-- 视频广告 -->
      <AdvSwiper class="advs" :advList="ATP_Play" v-if="!gridAdvList.length" />
      <!-- 为你推荐 -->
      <div class="video-recommend" v-if="mediaInfo.flags">
        <h2>为您推荐</h2>
        <VideoRecoments :flags="mediaInfo.flags" />
      </div>
    </div>
    <comment
      v-show="commentShow"
      :topicInfo="mediaInfo"
      @commentSuccess="commentSuccess"
      :commentShow="commentShow"
      @commentClose="commentShow = false"
    />
    <share
      v-if="shareShow"
      :topicInfo="mediaInfo"
      @shareSuccess="shareSuccess"
      :shareShow="shareShow"
      @shareClose="shareShow = false"
    />
    <forward
      :isForward="isForward"
      @forwardClose="isForward = false"
      :id="mediaInfo.id"
    />
    <!-- loading -->
    <van-overlay :show="loading" class="loading flex-center">
      <van-loading type="spinner" color="#f21313" size="24px" vertical
        >正在支付，请稍等</van-loading
      >
    </van-overlay>
    <!-- 切换路线框 -->
    <SelectionLinePopup
      v-if="showChangeRoadLine"
      @closeSelectLine="closeSelectLine"
      @close="showChangeRoadLine = false"
    />
  </div>
</template>

<script>
import { verifyPlayVideo } from "@/api/index.js";
import { sendUserCare } from "@/api/on_demand.js";
import { like } from "@/api/app.js";
import VideoRecoments from "@/views/videoInfo/VideoRecoments.vue";
import DPlayer from "@/components/VueDplayer/VideoPlay.vue";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import comment from "@/views/widget/comment.vue";
import share from "@/views/widget/share.vue";
import forward from "@/views/widget/forward.vue";
import { mapGetters } from "vuex";
import { sendMediaBuy } from "@/api/index.js";
import SelectionLinePopup from "@/components/SelectionLinePopup";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  components: {
    DPlayer,
    AdvSwiper,
    VideoRecoments,
    comment,
    share,
    forward,
    SelectionLinePopup,
  },
  computed: {
    ...mapGetters({
      walletInfo: "walletInfo",
      userInfo: "userInfo",
      adsTimeLongVideo: "adsTimeLongVideo",
    }),
  },
  watch: {
    $route() {
      if (Number(this.oldId) != Number(this.$route.query.id)) {
        this.oldId = this.$route.query.id;
        let dom = document.querySelector(".video-info");
        dom.scrollTop = 0;
        this.getVideoInfo();
      }
    },
    deep: true,
    mediaInfo() {
      this.clearTimer();
      this.getRandomAdv();
      this.countdown();
    },
  },
  data() {
    return {
      mediaInfo: {}, //视频信息
      ATP_Play: [],
      commentShow: false, //评论是否显示
      shareShow: false, //分享组件的显示与否
      isForward: false, //转发
      data: {},
      shopCartImg: require("@/assets/png/gouwuchekong.png"),
      qianbao: require("@/assets/png/qianbao.png"),
      loading: false,
      oldId: this.$route.query.id,
      vipInfo: "充值VIP享更多优惠",
      advsList: [],
      gridAdvList: [],
      timer: 0,
      isShowAdv: false, // 是否显示广告，默认true
      countdowner: null,
      showVideo: true, // 视频是否显示
      showChangeRoadLine: false, // 显示切换路线
    };
  },

  async created() {
    this.getRandomAdv();
    this.countdown();
    this.ATP_Play = getAdItem(AdType.ATP_Play);
    this.gridAdvList = getAdItem(AdType.videoAdv) || [];
    // console.log(this.gridAdvList);
    this.getVideoInfo(); //刷新余额
    this.$store.dispatch("user/setWallet");
    this.$store.dispatch("user/getUserInfo");
    //购买成功回调
    this.$bus.$on("paySuccess", async () => {
      this.data.playable = true;
      this.mediaInfo.isBuy = true;
      this.$refs.dp.buySuc();
      this.$store.dispatch("user/setWallet");
    });

    if (
      this.userInfo.vipType != 0 &&
      this.handleTime(this.userInfo.vipExpire * 1000) < 5
    ) {
      this.vipInfo = "VIP即将到期 立即续费享优惠";
    }
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    openSelectLine() {
      this.showChangeRoadLine = true;
    },
    // 关闭线路选择
    closeSelectLine() {
      this.showVideo = false;
      this.showChangeRoadLine = false;
      this.$nextTick(() => {
        this.showVideo = true;
      });
    },
    // 随机获取广告
    getRandomAdv() {
      this.advsList = [];
      let advs = getAdItem(AdType.freeVideo);
      if (advs.length > 0) {
        let index = Math.floor(Math.random() * advs.length);
        this.advsList.push(advs[index]);
      } else {
        this.isShowAdv = false;
      }
    },
    // 倒计时
    countdown() {
      this.timer = this.adsTimeLongVideo || 0;
      this.countdowner = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(this.countdowner);
        }
      }, 1000);
    },
    // 清除计时器
    clearTimer() {
      clearInterval(this.countdowner);
      this.countdowner = null;
    },
    clickVipInfo() {
      let vipGrap = {
        type: "video",
        vInfo: this.mediaInfo,
      };
      sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
      this.$router.push("/viplist");
    },
    jumpTag(name) {
      this.$router.push({ path: "searchTagResult", query: { name: name } });
    },
    // 处理时间
    handleTime(date) {
      let oldTime = date;
      let curTime = new Date().getTime();
      let day = 0;
      if (oldTime > curTime) {
        day = parseInt((oldTime - curTime) / (1000 * 3600 * 24));
      }
      return day;
    },
    //获取视频信息
    async getVideoInfo() {
      let req = {
        mediaId: Number(this.oldId),
      };
      let ret = await this.$Api(verifyPlayVideo, req);
      this.isShowAdv = true;
      if (ret.code == 200) {
        this.mediaInfo = ret.data.data.mediaInfo;
        // console.log(this.mediaInfo)
        this.data = ret.data.data;
        return;
      }
    },
    // 关注
    async follow() {
      let isCared = this.mediaInfo.author.cared;
      let req = {
        userId: Number(this.mediaInfo.author.id),
        isCare: !isCared,
      };
      let ret = await this.$Api(sendUserCare, req);
      if (ret.code == 200) {
        this.mediaInfo.author.cared = !isCared;
        this.$toast(!isCared ? "关注成功" : "取消关注");
        return;
      }
      this.$toast(ret.tip || "关注失败");
    },
    // 跳转博主详情
    jumpBlogger() {
      this.$router.push({
        path: "/bloggerDetails",
        query: { id: this.mediaInfo.author.id },
      });
    },
    // 点赞
    async like() {
      let isLike = !this.mediaInfo.bLike;
      let req = {
        mediaId: this.mediaInfo.id,
        isLike: isLike,
      };
      let ret = await this.$Api(like, req);
      if (ret.code == 200) {
        if (isLike) {
          this.$toast("点赞成功");
          this.mediaInfo.likeNum++;
          this.mediaInfo.bLike = true;
          return;
        } else {
          this.$toast("取消点赞");
          this.mediaInfo.likeNum--;
          this.mediaInfo.bLike = false;
          return;
        }
      }
      this.$toast(ret.tip || "点赞失败");
    },
    commentS() {
      this.commentShow = true;
    },
    commentSuccess() {
      this.mediaInfo.commentNum++;
    },
    //监听分享点击事件
    shareS() {
      this.shareShow = true;
    },
    //分享成功回调
    shareSuccess() {
      this.mediaInfo.shareNum++;
    },
    // 转发
    forward() {
      this.isForward = true;
    },
    //购买
    async buying() {
      let isvip = this.userInfo.vipType != 0;
      let price = this.mediaInfo.price;
      if (isvip) {
        price = this.mediaInfo.vipPrice;
      }
      let isCanbeUsedVideoTickets = false; //是否使用观影券

      //已购买
      if (this.mediaInfo.isBuy) {
        this.$toast("请勿重复购买");
        return;
      }
      //已加入粉丝团
      if (
        this.mediaInfo.fansGroup &&
        this.mediaInfo.fansGroup.isMember === true &&
        this.mediaInfo.fansGift === true
      ) {
        this.$toast("您已加入粉丝团，无需购买");
        return;
      }
      //粉丝团视频
      if (this.mediaInfo.fansGift) {
        this.$refs.dp.videoPayShow = true;
        return;
      }

      if (this.userInfo.videoTickets.length > 0) {
        this.userInfo.videoTickets.forEach((e) => {
          if (e.gold > price) {
            isCanbeUsedVideoTickets = true;
          }
        });
      }
      // 能够使用优惠券
      if (isCanbeUsedVideoTickets) {
        this.$refs.dp.videoPayShow = true;
        return;
      }

      this.loading = true;
      // 没有优惠券直接购买视频
      let ret = await this.$Api(sendMediaBuy, {
        mediaId: this.mediaInfo.id,
      });
      this.loading = false;
      // 余额不足
      if (ret.code == 6010) {
        this.$toast(ret.tip || "用户余额不足");
        let vipGrap = {
          type: "video",
          vInfo: this.mediaInfo,
        };
        sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
        this.$router.push("/wallet");
        return;
      }
      // 购买成功
      if (ret.code == 200) {
        this.data.playable = true;
        this.mediaInfo.isBuy = true;
        this.$refs.dp.buySuc();
        this.$store.dispatch("user/setWallet");
        return;
      }
      this.$toast(ret.tip || "购买失败");
    },
    //加入粉丝团成功
    fanSucvideo() {
      this.mediaInfo.fansGroup.isMember = true;
      this.data.playable = true;
      this.mediaInfo.isBuy = true;
      this.$store.dispatch("user/setWallet");
    },
  },

  beforeDestroy() {
    this.$bus.$off("paySuccess");
  },
};
</script>

<style lang="scss" scoped>
.video-info-warp {
  .adv /deep/ .swiperSlide {
    width: 240px;
    height: 135px;
  }
  // .advs /deep/ .swiperSlide {
  //   height: 160.7px;
  // }
  .advs /deep/ .swiperSlide {
    height: 104.16px;
  }
  .van-overlay {
    z-index: 2002;
  }
  .h_video {
    width: 100%;
    height: 211px;
    .videoAdv {
      width: 100%;
      height: 211px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.4);
      .advBox1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        width: 240px;
        height: 135px;
      }
      .timer_close {
        font-size: 12px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        background: #00000050;
        padding: 4px 10px;
        border-radius: 40px;
        color: white;
        span {
          color: #ff7f0f;
        }
      }
      .adv {
        /deep/ .swiper-container {
          width: 240px;
          height: 135px;
        }
        /deep/ img {
          width: 240px;
          height: 135px;
          object-fit: fill;
        }
      }
    }
    .dplayer {
      width: 100%;
      height: 211px;
    }
  }
  .video-info {
    width: 100%;
    height: calc(100% - 211px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .vipInfo {
      margin: 10px;
      background: #ed4754;
      color: #fff;
      padding: 8px 0;
      text-align: center;
      border-radius: 25px;
      font-size: 18px;
    }
    .video-desc {
      padding: 0 10px;
      h2 {
        padding-top: 10px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }
      .video-info-watch {
        font-size: 12px;
        padding: 10px 0 0 0;
        color: #767577;
      }
      .video-tag {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          display: inline-block;
          padding: 4px 6px;
          margin: 5px 5px 3px 0;
          color: #767577;
          border-radius: 3px;
          background: rgba($color: #a8a7a9, $alpha: 0.3);
        }
      }
      .video-blogger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .video-blogger-info {
          display: flex;
          .blogger-avatar {
            width: 30px;
            height: 30px;
            padding: 3px;
            background: url("../../assets/png/newyear_head_back.png") no-repeat;
            background-size: 100%;
            flex-shrink: 0;
          }
          h2 {
            padding-left: 10px;
            width: 240px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
          }
        }
        .video-blogger-follow {
          padding: 5px 12px;
          color: #fff;
          background: #ed4754;
          font-size: 12px;
          border-radius: 16px;
        }
      }
      .video-operate {
        margin: 16px 0 0 0;
        display: flex;
        font-size: 12px;
        justify-content: space-around;
        .video-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          /deep/,
          van-icon-sort {
            color: red !important;
          }
          span {
            display: inline-block;
            color: $vermillion;
          }
          span:nth-child(1) {
            width: 18px;
            height: 18px;
            background-size: 100%;
          }
          span:nth-child(2) {
            padding: 4px 0 0 0;
          }
          .message {
            background: url("../../assets/png/message.png") center center
              no-repeat;
          }
          .like {
            background: url("../../assets/png/likeno.png") center center
              no-repeat;
          }
          .actived {
            background: url("../../assets/png/like.png") center center no-repeat;
          }
          .forward {
            background: url("../../assets/png/forward.png") center center
              no-repeat;
          }
          .earnVip {
            background: url("../../assets/png/earnVip.png") center center
              no-repeat;
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 5px;
      background: rgba($color: #e5e5e6, $alpha: 0.53);
      margin: 20px 0 10px 0;
    }
    .video-recommend {
      padding: 0 10px;
      h2 {
        font-size: 15px;
        padding: 10px 0 0 0;
      }
    }
  }
}
.back-box {
  position: fixed;
  top: 20px;
  left: 15px;
  z-index: 103;
  .back {
    width: 20px;
    height: 20px;
    color: #fff;
  }
}
//购物车
.shopCart-box {
  position: fixed;
  z-index: 2;
  left: 50%;
  margin-left: -130px;
  bottom: 13%;
  width: 260px;
  height: 50px;
  background: #343030;
  border: 1px solid #ffb434;
  border-radius: 29px;
  display: flex;
  justify-content: space-between;
  .shopCart {
    position: absolute;
    left: 50%;
    margin-left: -32px;
    margin-top: -7px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #343030;
    border: 1px solid #ffb434;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #f6ca83;
    box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.5);
    font-size: 13px;

    img {
      width: 30px;
    }
  }
  .shop-info {
    width: 90px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;

    .vip-box {
      color: #fff;
      span:nth-child(1) {
        display: inline-block;
        background: #f2971d;
        margin: 3px 0 3px 10px;
        width: 40px;
        text-align: center;
        padding: 2px 0;
        border-radius: 12px;
      }
      .novip {
        background: #d3d3d4 !important;
      }
      span:nth-child(2) {
        padding-left: 5px;
      }
      .vip {
        color: #f2971d !important;
      }
    }
    .wallet {
      color: #fbe982;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        padding-right: 5px;
      }
    }
    .wallet-money {
      margin: 5px 0;
      color: #f6ca83;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.videoAdvBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // direction: rtl;
  // margin-top: -59px;
  grid-gap: 11px;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  // position: relative;
  z-index: 99999;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
      height: 14px;
    }
  }
}
.horizontalLine {
  padding: 0 16px;
  box-sizing: border-box;
  height: 1px;
  background: #e6e6e6;
  margin-top: 18px;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
